import { LEARN_INVEST } from "./contentstack";

export const main = [
{
    "id":"home",
    "text":"Home",
    "expandable":false,
    "route":{
        "path":"/"
    },
    "exact":true
}
,{
    "id": "markets",
    "text": "Markets",
    "expandable": true,
    "route": {
        "name": "markets"
    }
},
{
    "id": "investments",
    "text": "Investments",
    "expandable": true,
    "route": {
        "name": "investments"
    }
},
{
    "id": "insights",
    "text": "News and Insights",
    "expandable": true,
    "route": {
        "name": "insights"
    }
},
{
    "id": "learn",
    "text": "Learn",
    "expandable": true,
    "route": {
        "name": "learn"
    }
},
{
    "id": "solutions",
    "text": "About",
    "expandable": true,
    "route": {
        "name": "solutions"
    }
}];

export const markets = {
    centre: [{
        "id": "markets-australia",
        "text": "Australia",
        "route": {
            "name": "markets-region",
            "params": {
                "region": "australia"
            }
        },
        "title": "Australian Markets"
    },
    {
        "id": "markets-united-states",
        "text": "United States",
        "route": {
            "name": "markets-region",
            "params": {
                "region": "united-states"
            }
        },
        "title": "US Markets"
    },
    {
        "id": "markets-europe",
        "text": "Europe",
        "route": {
            "name": "markets-region",
            "params": {
                "region": "europe"
            }
        },
        "title": "European Markets"
    },
    {
        "id": "markets-asia",
        "text": "Asia",
        "route": {
            "name": "markets-region",
            "params": {
                "region": "asia"
            }
        },
        "title": "Asian Markets"
    }],
    reports: [{
        "id": "markets-asx-market-report",
        "text": "ASX Market Report",
        "microcopy": "Roundup of ASX market movements",
        "route": {
            "name": "markets-asx-market-report",
            "params": {
                "market": "asx"
            }
        },
        "title": "ASX Today Live News & Analysis"
    },
    {
        "id": "markets-global-market-report",
        "text": "Global Market Report",
        "microcopy": "Roundup of Global market movements",
        "route": {
            "name": "markets-global-market-report-market",
            "params": {
                "market": "australia"
            }
        }
    }],
    calendar: [
        {
            "id": "markets-upcoming-dividends",
            "text": "Upcoming Dividends",
            "microcopy": "Dividend payments in the coming 90 days",
            "route": {
                "name": "markets-upcoming-dividends"
            },
            "description": "Australian and New Zealand companies paying a cash dividend over the next 90 days."
        }
    ]
};

export const insights = {
    topic: [
        {
            "id": "insights-category-top-stories",
            "text": "Top Stories",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "top-stories"
                }
            },
            "title": "Latest News & Analysis",
            "active": false
        },
        {
            "id": "insights-category-markets",
            "text": "Markets",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "markets"
                }
            },
            "title": "Markets News & Analysis",
            "active": false
        },
        {
            "id": "insights-category-personal-finance",
            "text": "Personal Finance",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "personal-finance"
                }
            },
            "title": "Personal Finance News",
            "active": false
        },
        {
            "id": "insights-category-retirement",
            "text": "Retirement",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "retirement"
                }
            },
            "title": "Retirement News",
            "active": false
        }, {
            "id": "insights-category-stocks",
            "text": "Stocks",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "stocks"
                }
            },
            "title": "Stock Market News & Analysis",
            "active": false
        },
        {
            "id": "insights-category-etfs",
            "text": "ETFs",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "etfs"
                }
            },
            "title": "ETF News & Analysis",
            "active": false
        },
        {
            "id": "insights-category-funds",
            "text": "Funds",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "funds"
                }
            },
            "title": "Funds News & Analysis",
            "active": false
        },
        {
            "id": "insights-category-income",
            "text": "Fixed Income",
            "route": {
                "name": "insights-category",
                "params": {
                    "category": "fixed-income"
                }
            },
            "title": "Fixed Income News & Analysis",
            "active": false
        },
    ],

    investment: [{
        "id": "insights-category-stocks",
        "text": "Stocks",
        "route": {
            "name": "insights-category",
            "params": {
                "category": "stocks"
            }
        },
        "description": "Successful investors pay attention to stocks, the sectors they belong to, and how the companies are positioned for future returns. Learn about the balance sheets and bottom lines here. Stay up to date on the latest breaking news and analysis on stocks."
    },
    {
        "id": "insights-category-etfs",
        "text": "ETFs",
        "route": {
            "name": "insights-category",
            "params": {
                "category": "etfs"
            }
        },
        "description": "Exchange-traded funds, or ETFs, are investment vehicles traded on an exchange like the Australian Stock Exchange (ASX) and can be a low-cost way to diversify your investments. Stay up to date on the latest breaking news and analysis on ETFs."
    },
    {
        "id": "insights-category-funds",
        "text": "Funds",
        "route": {
            "name": "insights-category",
            "params": {
                "category": "funds"
            }
        },
        "description": "Learn about the people, processes and principles behind established and emerging managed funds. Stay up to date on the latest breaking news and analysis on funds."
    }],

    industry: [{
        "id": "insights-category-advisor-spotlight",
        "text": "Adviser Spotlight",
        "external": true,
        "external_url": "https://spotlight.morningstarhub.com.au/?utm_source=morningstar.com.au&utm_medium=menu-header-adviser-spotlight",
        "route": {
            "name": "insights-advisor-spotlight"
        }
    },
    {
        "id": "insights-category-asset-manager-monthly",
        "text": "Manager Spotlight",
        "external": true,
        "external_url": "https://go.morningstar.com/subscribe-manager-spotlight?utm_source=morningstar.com.au&utm_medium=menu-header-manager-spotlight",
        "route": {
            "name": "insights-asset-manager-monthly"
        }
    },
    {
        "id": "insights-category-firstlinks",
        "text": "Firstlinks",
        "external": true,
        "external_url": "https://www.firstlinks.com.au/?utm_source=morningstar.com.au&utm_medium=menu-header-firstlinks",
        "route": {
            "name": "insights-firstlinks"
        }
    }]
};

export const learn = {
    investingContent: [{
        "id": "investing-guides",
        "text": "Investing guides",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investment guides',
                subtitle: "Our guides to investing",
                category: "investing-content",
                slug: "investing-guides",
                stackId: LEARN_INVEST.Investing_Guides,
            }
        }
    }, {
        "id": "webinars",
        "text": "Webinars",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investment webinars',
                subtitle: "Our webinars on investing fundamentals and strategy",
                category: "investing-content",
                slug: "webinars",
                stackId: LEARN_INVEST.Webinars_Id,
            }
        }
    }, {
        "id": "podcasts",
        "text": "Podcasts",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investing Compass podcast',
                subtitle: "Our podcasts on investing fundamentals and strategy",
                category: "investing-content",
                slug: "podcasts",
                stackId: LEARN_INVEST.Podcasts_Id,
            }
        }
    }, {
        "id": "articles",
        "text": "Articles",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investing fundamentals',
                subtitle: "Our bank of investing articles",
                category: "investing-content",
                slug: "articles",
                stackId: LEARN_INVEST.Articles_Id,
            }
        }
    }],
    learnToInvestIn: [{
        "id": "stocks",
        "text": "Stocks",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investing in shares',
                subtitle: "Learn about stock investing",
                category: "learn-to-invest-in",
                slug: "stocks",
                stackId: LEARN_INVEST.Stocks,
            }
        }
    }, {
        "id": "bonds",
        "text": "Bonds",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investing in bonds',
                subtitle: "Learn about bond investing",
                category: "learn-to-invest-in",
                slug: "bonds",
                stackId: LEARN_INVEST.Bonds,
            }
        }
    }, {
        "id": "etfs-funds",
        "text": "ETFs/Funds",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Investing in ETFs and funds',
                subtitle: "Learn about exchange-traded fund and managed fund investing",
                category: "learn-to-invest-in",
                slug: "etfs-funds",
                stackId: LEARN_INVEST.ETFs_Funds,
            }
        }
    }],
    howToInvest: [{
        "id": "getting-started",
        "text": "Getting started",
        "route": {
            "name": "learn-slug",
            "params": {
                title: "Getting started",
                subtitle: "Resources to help you begin your investing journey",
                category: "would-like-to",
                slug: "getting-started",
                stackId: LEARN_INVEST.Getting_Started,
            }
        }
    }, {
        "id": "goals-based-portfolio-construction",
        "text": "Goals based portfolio construction",
        "route": {
            "name": "learn-slug",
            "params": {
                title: "Goals based portfolio construction",
                subtitle: "Using a goals-based approach to investing",
                category: "would-like-to",
                slug: "goals-based-portfolio-construction",
                stackId: LEARN_INVEST.Goals_Based_Portfolio_Construction,
            }
        }
    }, {
        "id": "portfolio-maintenance",
        "text": "Portfolio maintenance",
        "route": {
            "name": "learn-slug",
            "params": {
                title: "Portfolio maintenance",
                subtitle: "Maintaining your portfolio to reach your investing goals",
                category: "would-like-to",
                slug: "portfolio-maintenance",
                stackId: LEARN_INVEST.Portfolio_Maintenance,
            }
        }
    }, {
        "id": "retirement-strategies",
        "text": "Retirement strategies",
        "route": {
            "name": "learn-slug",
            "params": {
                title: "Retirement strategies",
                subtitle: "Investing for your post-work life",
                category: "would-like-to",
                slug: "retirement-strategies",
                stackId: LEARN_INVEST.Retirement_Strategies,
            }
        }
    }],
    ourResearch: [{
        "id": "morningstar-equity-research-methodology",
        "text": "Morningstar equity research methodology",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Morningstar equity research methodology',
                subtitle: "Our research methodology for rating equities",
                category: "our-research",
                slug: "morningstar-equity-research-methodology",
                stackId: LEARN_INVEST.Morningstar_Equity_Research_Methodology,
            }
        }
    }, {
        "id": "morningstar-manager-research-methodology",
        "text": "Morningstar manager research methodology",
        "route": {
            "name": "learn-slug",
            "params": {
                title: 'Morningstar manager research methodology',
                subtitle: "Our research methodology for rating managed investments",
                category: "our-research",
                slug: "morningstar-manager-research-methodology",
                stackId: LEARN_INVEST.Morningstar_Manager_Research_Methodology,
            }
        }
    }]
};

export const investments = {
    security: [{
        "id": "investments-stocks",
        "text": "Stocks",
        "route": {
            "name": "investments-stocks"
        }
    },
    {
        "id": "investments-funds",
        "text": "Funds",
        "route": {
            "name": "investments-funds"
        }
    },
    {
        "id": "investments-etfs",
        "text": "ETFs",
        "route": {
            "name": "investments-etfs"
        }
    },
    {
        "id": "investments-lics",
        "text": "LICs",
        "route": {
            "name": "investments-lics"
        }
    },
    {
        "id": "investments-hybrids",
        "text": "Hybrids",
        "route": {
            "name": "investments-hybrids"
        }
    }],
    theme: [{
        "id": "investments-income",
        "text": "Income Investments",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-income"
        }
    },
    {
        "id": "investments-sustainable",
        "text": "Sustainable Investments",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-sustainable"
        }
    },
    {
        "id": "investments-global",
        "text": "Global Investing",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-global"
        }
    },
    {
        "id": "investments-smallcap",
        "text": "Small Cap",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-smallcap"
        }
    },
    {
        "id": "investments-long-term-gainers",
        "text": "Long Term Gainers",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-long-term-gainers"
        }
    },
    {
        "id": "investments-value-core-growth",
        "text": "Value/Core/Growth",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-value-core-growth"
        }
    },
    {
        "id": "investments-bluechip",
        "text": "Blue chip",
        "microcopy": "Lorem ipsum dolor sit amet",
        "route": {
            "name": "investments-bluechip"
        }
    }],
    ideas: [{
        "id": "investments-ideas",
        "text": "Investment Ideas",
        "route": {
            "name": "investments-ideas"
        },
        "title": "Stocks, ETFs & Funds Investment Ideas Screener"
    }]
};

export const solutions = {
    products: [
        {
            "id": "solutions-advisers",
            "text": "For Advisers",
            "microcopy": "",
            "route": {
                "name": "solutions-solution",
                "params": {
                    solution: "advisers"
                }
            }
        },
        {
            "id": "solutions-licensees-and-self-licensed-practices",
            "text": "For Licensees & Self-Licensed Practices",
            "microcopy": "",
            "route": {
                "name": "solutions-solution",
                "params": {
                    solution: "licensees-and-self-licensed-practices"
                }
            }
        },
        {
            "id": "solutions-asset-managers",
            "text": "For Asset Managers",
            "microcopy": "",
            "route": {
                "name": "solutions-solution",
                "params": {
                    solution: "asset-managers"
                }
            }
        },
        {
            "id": "solutions-individual-investors",
            "text": "For Individual Investors",
            "microcopy": "",
            "route": {
                "name": "solutions-solution",
                "params": {
                    solution: "individual-investors"
                }
            }
        }
    ],
    featured: [
        {
            "id": "products-adviserlogic",
            "text": "AdviserLogic",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "adviserlogic"
                }
            }
        },
        {
            "id": "products-adviser-research-centre",
            "text": "Adviser Research Centre",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "adviser-research-centre"
                }
            }
        },
        {
            "id": "products-finametrica",
            "text": "FinaMetrica",
            "external": true,
            "external_url": "https://riskprofiling.com/?utm_source=morningstar.com.au&utm_medium=menu-header-finametrica",
            "microcopy": "",
            "route": {
                "name": "products-finametrica"
            }
        },
        {
            "id": "products-morningstar-direct",
            "text": "Morningstar Direct",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "morningstar-direct"
                }
            }
        },
        {
            "id": "solutions-mim",
            "text": "Morningstar Investment Management",
            "external": true,
            "external_url": "https://morningstarinvestments.com.au/?utm_source=morningstar.com.au&utm_medium=menu-header-investment-management",
            "microcopy": "",
            "route": {
                "name": "solutions-mim"
            }
        },
        {
            "id": "products-licensee-solutions",
            "text": "Licensee Dashboard & CRM",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "licensee-solutions"
                }
            }
        },
        {
            "id": "products-manager-selection-services",
            "text": "Manager Selection Services",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "manager-selection-services"
                }
            }
        },
        {
            "id": "products-morningstar-essentials",
            "text": "Morningstar Essentials",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "morningstar-essentials"
                }
            }
        },
        {
            "id": "products-indexes",
            "text": "Morningstar Indexes",
            "external": "true",
            "external_url": "https://indexes.morningstar.com/?utm_source=morningstar.com.au&utm_medium=menu-header-indexes",
            "microcopy": "",
            "route": {
                "name": "products-indexes"
            }
        },
        {
            "id": "products-morningstar-data",
            "text": "Morningstar Data",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "morningstar-data"
                }
            }
        },
        {
            "id": "products-paylogic",
            "text": "PayLogic",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "paylogic"
                }
            }
        },
        {
            "id": "products-morningstar-investor",
            "text": "Morningstar Investor",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "morningstar-investor"
                }
            }
        },
        {
            "id": "products-sustainalytics",
            "text": "Sustainalytics",
            external: true,
            external_url: "https://www.sustainalytics.com/?utm_source=morningstar.com.au&utm_medium=menu-header-sustainalytics",
            "microcopy": "",
            "route": {
                "name": "products-sustainalytics"
            }
        },
        {
            "id": "products-esg-investing",
            "text": "ESG Investing",
            "microcopy": "",
            "route": {
                "name": "products-product",
                "params": {
                    product: "esg-investing"
                }
            }
        }
    ],
    about: [{
        "id": "about-story",
        "text": "Our Story",
        "external": true,
        "external_url": "https://www.morningstar.com/company/about-us",
        "route": {
            "name": "about-story"
        }
    },
    {
        "id": "about-methodology",
        "text": "Our Methodology",
        "external": true,
        "external_url": "https://www.morningstar.com/company/disclosures",
        "route": {
            "name": "about-methodology"
        }
    },
    {
        "id": "mic-professionals",
        "text": "Morningstar Investment Conference for Financial Professionals",
        "external": true,
        "external_url": "https://morningstar.cventevents.com/d/dlqgk0/",
        "route": {
            "name": "mic-professionals"
        }
    },
    {
        "id": "mic-individuals",
        "text": "Morningstar Investment Conference for Individual Investors",
        "external": true,
        "external_url": "https://www.morningstar.com/en-au/events/miic",
        "route": {
            "name": "mic-individuals"
        }
    },
    {
        "id": "mic-adviser-roadshow",
        "text": "Morningstar Adviser Roadshow",
        "external": true,
        "external_url": "https://cvent.me/ErLyqP?RefId=MAR_MCA"
    },
    {
        "id": "subscribe-to-our-newsletters",
        "text": "Subscribe to our newsletters",
        "route": {
            "name": "newsletters-subscribe"
        }
    },
    {
        "id": "about-advertise",
        "text": "Advertise with Us",
        "route": {
            "name": "about-advertise"
        }
    },
    {
        "id": "about-careers",
        "text": "Careers",
        "external": true,
        "external_url": "https://www.morningstar.com/careers",
        "route": {
            "name": "about-careers"
        }
    },
    {
        "id": "about-contact",
        "text": "Contact Us",
        "route": {
            "name": "about-contact"
        }
    }]
};