
import Vue from "vue";
import type { Route } from "vue-router";
import MdsPageShell from "@mds/page-shell";
import { MdsButton } from "@mds/button";
import McaSearch from "@mca-components-ui/search";
import  MainNavigation, { IMainNav } from "@/components/layout/main-navigation.vue";
import MobileNavigation from "@/components/layout/mobile-navigation.vue";
import UserTypeSelection from "@/components/common/UserTypeSelection.vue";
import McaFooter, { IColumn }  from "@mca-components-ui/footer-v2";
import Logo from "~/assets/logo.svg";
import { debounce } from "debounce";
import type { MCA } from "@mca-components-ui/types";
import { SearchResult } from "@mca-components-ui/models";
import SearchData from "~/services/search/search";
import FixedPullDownAdvert from '@/components/adverts/FixedPullDown.vue';
import { AdvertPlacement } from '@/configs/adverts';
import McaAdvertContainer from '@mca-components-ui/advert-container';
import { mapGetters } from "vuex";
import MdsBanner from "@mds/banner";
import { MetaInfo, MetaPropertyName } from "vue-meta";
import { Gtm } from "~/configs/common";

export default Vue.extend({
    data() {
        return {
            Logo,
            mainNavigation:{} as IMainNav,
            search: {
                results: {
                    securities: [],
                    documents: [],
                },
                isLoading: false,
            },
            wait: 500,
            searchQuery: "",
            minlength: 3,
            isAccountMenuOpen: false,
            history: [] as string[],
            exchangeCode: "EX$$$$XASX",
            isMobileViewport: false,
            showPageTopBanner: true
        };
    },
    head(): MetaInfo {
        return {
            meta: [
                this.robotsMeta
            ],

            link: [
                {
                    hid: "canonical-link",
                    rel: "canonical",
                    href: `https://${this.$store.state.host}${this.$route.path === '/' ? "" : this.$route.path}`,
                },
            ],
        };
    },
    errorCaptured(error, vm, info) {
        console.log(error, vm, info);
        return false; // prevent Nuxt from handling the error
    },
    computed: {
        ...mapGetters({
            isMobile: "layout/isMobileViewport"
        }),
        robotsMeta(): MetaPropertyName  {
            if (process.env.CS_ENVIRONMENT === 'dev' || process.env.CS_ENVIRONMENT === 'qa') {
                return { hid: 'robots', name: "robots", content: "noindex" };
            }
            else {
                return { hid: 'robots', name: "robots", content: "index, follow" };
            }
        },
        currentRoute(): Route {
            return this.$nuxt.$route;
        },
        isProductPage(): boolean {
            return this.$route.name === "products-product";
        },
        isSolutionPage(): boolean {
            return this.$route.name === "solutions-solution";
        },
        isSearchOpen(): boolean {
            return this.$store.state.search.isOpen;
        },
        securities(): MCA.Api.SearchResult.ISecurity[] {
            let searchData = new SearchData(this.$apollo.getClient());
            return searchData
                .getAllSearchResults(this.search.results.securities)
                .slice(0, 7);
        },
        documents(): MCA.Api.SearchResult.IDocument[] {
            return this.search.results.documents.slice(0, 7);
        },
        adverts(): Record<AdvertPlacement, string> {
            return this.$store.state.adverts.placements;
        },
        footerColumns(): IColumn {
            return this.$store.state.contentstack.footer.columns;
        },
        footerDisclaimer(): string {
            return this.$store.state.contentstack.footer.disclaimer;
        }
    },
    components: {
        MdsPageShell,
        MdsButton,
        McaSearch,
        McaFooter,
        MainNavigation,
        MobileNavigation,
        UserTypeSelection,
        FixedPullDownAdvert,
        McaAdvertContainer,
        MdsBanner
    },
    mounted(): void {

        this.resize();

        this.resize = this.resize.bind(this);

        window.addEventListener("resize", debounce(this.resize, 250), { passive: true });
        this.$nextTick(()=>{
            this.isMobileViewport = Boolean(this.isMobile);
        });
    },
    watch: {
        $route(): void {
            this.$store.commit("search/SET_SEARCH", false);
        },
        isMobile(val) {
            this.isMobileViewport = val;
        }
    },
    async fetch() {
        await this.$store.dispatch("contentstack/getFooterPage");
    },
    methods: {
        resize() {
            this.$store.commit('layout/WINDOW_RESIZE', {
                width: window.innerWidth,
                height: window.innerHeight,
            });
        },
        openSearch(): void {
            this.$store.commit("search/SET_SEARCH", !this.isSearchOpen);
            this.mainNavigation.close();
            /**
             * Triggered when search button is clicked
             * @event open-search
             * @type {Event}
             */
            this.$emit("open-search");
        },
        getNavInterface(setMainNavInterface: IMainNav) : void {
            this.mainNavigation = setMainNavInterface;
        },
        mainNavClicked() {
            this.$store.commit("search/SET_SEARCH", false);
        },
        async onSearch(term: string): Promise<void> {
            let searchData = new SearchData(this.$apollo.getClient());
            this.searchQuery = term;
            this.search.isLoading = true;
            if (term !== "") {
                this.history = searchData.addHistoryItem(this.history, term);
                const response = await searchData.getSearchResultsFor(term);

                this.$gtm.push({
                    event: Gtm.eventName,
                    category: "search",
                    action: 'search_results',
                    term,
                    totalSecurities: response.searchSecurities.total || 0,
                    totalDocuments: response.searchDocuments.total || 0,
                });

                this.search.isLoading = false;
                this.search.results.documents =
            response.searchDocuments.documents.map(
                (document: MCA.Api.SearchResult.IDocument) =>
                    new SearchResult.Document(document)
            );
                this.search.results.securities =
            response.searchSecurities.securities.map(
                (security: MCA.Api.SearchResult.ISecurity) =>
                    new SearchResult.Security(security)
            );
            }
        },
        onNavigateTo(route: Route & { exchangeCode: string, symbol: string,title: string }): void {
            let title = '';
            let action = '';
            if(route.params.type === "securities") {
                title = route.exchangeCode + ":" + route.symbol;
                action = "security-navigate";
            }
            else if(route.params.type === "documents") {
                title = route.title;
                action = 'document-navigate';
            }

            this.$gtm.push({
                event: Gtm.eventName,
                category: "search",
                action,
                term: this.searchQuery,
                title,
            });

            this.$store.commit("search/SET_SEARCH", false);
        },
        resetSearch(): void {
            this.$store.commit("search/SET_SEARCH", false);
        },
        onRemoveHistoryItem(event: string): void {
            const index = this.history.indexOf(event);
            if (index !== -1) {
                this.history.splice(index, 1);
            }
        },
        onLogin(): void {
            this.$auth.loginWith("oidc");
        },
        onLogout(): void {
            this.$auth.logout();
        },
        onCloseMobileNav(): void {
            (this.$refs?.pageShell as any)?.hideHiddenNav();
        },
        onHiddenNavOpen(): void {
            this.showPageTopBanner = false;
        },
        onHiddenNavClose(): void {
            this.showPageTopBanner = true;
        }
    },
});

