import * as Content from '~/configs/contentstack';

import { getFirstEntry } from './common';
import { getStack } from './stack';

import type { NewsletterCtaPlacement, UserType } from './types';

class ContentStack {

    async getLandingPage(id: string) {

        try {
            const stack = getStack();
            const responses = await stack.ContentType('landing_pages')
                .Query()
                .where('slug', id)
                .includeReference(
                    [
                        'content_sections.introduction.section.cta_button',
                        'content_sections.value_prop.section',
                        'content_sections.cta.section.common_cta_button',
                        'content_sections.disclaimer.section'
                    ]
                )
                .toJSON()
                .find();

            return getFirstEntry(responses);

        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getProductPage(slugID: string) {
        try {
            const stack = getStack();
            const responses = await stack
                .ContentType('product_pages')
                .Query()
                .where('product_slug_id', slugID)
                .includeReference(
                    [
                        'content_sections.introduction.section.cta_button',
                        'content_sections.value_prop.section',
                        'content_sections.cta.section.common_cta_button',
                        'content_sections.disclaimer.section',
                        'content_sections.case_studies.section',
                        'content_sections.process.section.modular_blocks.item.cta_button',
                        'content_sections.features_1.section',
                        'content_sections.features_2.section.modular_blocks.item.lists.list_item.cta_button',
                        'content_sections.capabilities_1.section',
                        'content_sections.capabilities_2.section.modular_blocks.item.cta_button',
                        'content_sections.awards.section'
                    ]
                )
                .toJSON()
                .find();
            return getFirstEntry(responses);
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getSolutionPage(slugID: string) {
        try {
            const stack = getStack();
            const responses = await stack
                .ContentType('solutions_pages')
                .Query()
                .where('solution_slug_id', slugID)
                .includeReference(
                    [
                        'content_sections.introduction.section.cta_button',
                        'content_sections.value_prop.section',
                        'content_sections.cta.section.common_cta_button',
                        'content_sections.disclaimer.section',
                        'content_sections.case_studies.section',
                        'content_sections.capabilities.section.modular_blocks.item.button',
                        'content_sections.whitepaper_single.section.button',
                        'content_sections.whitepaper_multiple.section.modular_blocks.item.button',
                        'content_sections.advice_timeline_process.section'
                    ]
                )
                .toJSON()
                .find();
            return getFirstEntry(responses);
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getHomePageCTAButtonMap() {
        try {
            const stack = getStack();
            const response = await stack
                .ContentType('home_page_cta_button_map')
                .Entry('blt4eb6ff56fade7c23')
                .includeReference(['user_segment_button_map.item.button'])
                .toJSON()
                .fetch();
            return response;
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getFeaturedInsights() {
        try {
            const stack = getStack();
            return await stack
                .ContentType('featured_insights')
                .Entry('blt8b57b184290710ad')
                .toJSON()
                .fetch();
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getWebinarPodcast() {
        try {
            const stack = getStack();
            const response = await stack
                .ContentType(Content.WEBINAR_PODCAST.ContentType)
                .Entry(Content.WEBINAR_PODCAST.Webinar_Podcast_Id)
                .includeReference(['modular_blocks.card.button'])
                .toJSON()
                .fetch();
            return response;
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getBestIdeasSection() {
        try {
            const stack = getStack();
            return await stack
                .ContentType(Content.BEST_IDEAS_SECTION.ContentType)
                .Entry(Content.BEST_IDEAS_SECTION.EntryId)
                .includeReference(
                    [
                        'modular_blocks.best_ideas_card_and_modal.best_ideas_card',
                        'modular_blocks.best_ideas_card_and_modal.best_ideas_card.button',
                        'modular_blocks.best_ideas_card_and_modal.best_ideas_modal',
                        'modular_blocks.best_ideas_card_and_modal.best_ideas_modal.button',
                    ]
                )
                .toJSON()
                .fetch();
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getThankyouContent(thankyouType: string) {
        const config = Content.THANK_YOU.get(thankyouType);
        if (config && config.entryId && config.contentType) {
            const stack = getStack();
            const response = await stack
                .ContentType(config.contentType)
                .Entry(config.entryId)
                .includeReference(['thank_you_page_line_up.thank_you.cta_button'])
                .toJSON()
                .fetch();

            return response.thank_you_page_line_up || [];
        }
    }
    async getThankyouPageContent(pageId: string) {
        const stack = getStack();
        const responses = await stack
            .ContentType('thank_you_pages')
            .Query()
            .where('page_id', pageId)
            .toJSON()
            .find();
        return getFirstEntry(responses);
    }

    async getResearchMethodologies() {
        try {
            const stack = getStack();
            return await stack
                .ContentType('disclaimers')
                .Query()
                .tags(['our research methodologies'])
                .toJSON()
                .find();
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                console.log(err);

                throw err;
            }
        }
    }

    async getPageMetadata(page_id: string) {
        try {
            const stack = getStack();
            const responses = await stack.ContentType('static_pages')
                .Query()
                .where("page_id", page_id)
                .toJSON()
                .find();

            return getFirstEntry(responses);

        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getNewsletterSignupPage() {
        try {
            const stack = getStack();
            return await stack
                .ContentType(Content.NEWSLETTER_SIGNUP_PAGE.ContentType)
                .Entry(Content.NEWSLETTER_SIGNUP_PAGE.EntryId)
                .includeReference(['form'])
                .toJSON()
                .fetch();
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                console.log(err);
                throw err;
            }
        }
    }

    async getSecurityCta() {
        const stack = getStack();
        return await stack
            .ContentType(Content.SECURITY_CTA.ContentType)
            .Entry(Content.SECURITY_CTA.EntryId)
            .toJSON()
            .fetch();
    }

    async getNewsletterCta(placement: string, userType: UserType): Promise<NewsletterCtaPlacement> {

        const stack = getStack();
        const placementQuery = stack.ContentType(Content.NEWSLETTER_CTA.ContentType).Query().where('placement_id', placement);
        const segmentQuery = stack.ContentType(Content.NEWSLETTER_CTA.ContentType).Query().where('user_segment', userType);

        return await stack
            .ContentType(Content.NEWSLETTER_CTA.ContentType)
            .Query()
            .and(placementQuery, segmentQuery)
            .toJSON()
            .findOne();
    }

    async getFooterPage() {
        try {
            const stack = getStack();
            return await stack
                .ContentType(Content.FOOTER_PAGE.ContentType)
                .Entry(Content.FOOTER_PAGE.EntryId)
                .toJSON()
                .fetch();
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }

    async getHeadMeta(pageId: string) {
        try{
            const stack = getStack();
            return await stack
                .ContentType(Content.HEAD_META.ContentType)
                .Query()
                .where('page_id', pageId)
                .toJSON()
                .findOne();
        }
        catch (err) {
            return null;
        }
    }

    async getRecommendedTagsForSecurity(exchange: string, ticker: string, secType: string) {
        const securityTypeMap = new Map([
            ['ST', 'stocks'],
            ['FO', 'funds'],
            ['FE', 'etfs'],
            ['FC', 'lics'],
            ['IX', 'indexes']
        ]);
        try {
            const stack = getStack();
            const QueryForSpecificSecurity = stack
                .ContentType(Content.RECOMMENDED_SECTION_SAL.ContentType)
                .Query()
                .query({
                    exchange: exchange,
                    ticker: ticker
                });
            const QueryForFallback = stack
                .ContentType(Content.RECOMMENDED_SECTION_SAL.ContentType)
                .Query()
                .where('title', securityTypeMap.get(secType) || '');
            const response = await stack
                .ContentType(Content.RECOMMENDED_SECTION_SAL.ContentType)
                .Query()
                .or(QueryForFallback, QueryForSpecificSecurity)
                .toJSON()
                .findOne();
            return response;
        }
        catch (err) {
            if (err === 'Unprocessable Entity') {
                return null;
            }
            else {
                throw err;
            }
        }
    }
}

export default ContentStack;