import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2d08b224"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=2d08b224&prod&lang=scss"
import style1 from "./default.vue?vue&type=style&index=1&id=2d08b224&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutCtaButton: require('/user/src/app/app/components/layout/cta-button.vue').default})
