import GetDocumentQuery from "~/services/queries/getDocument.gql";
import GetNewsQuery from "~/services/queries/getNews.gql";
import { ApolloClient } from 'apollo-client';
import DocumentRequest from "~/types/document/documentRequest";
import NewsRequest from "~/types/document/newsRequest";
import { Content } from "@mca-components-ui/models";

class DocumentService {
    constructor(public gqlClient: ApolloClient<any>) { }
    async getDocument(payload: DocumentRequest) {
        return this.gqlClient.query({
            query: GetDocumentQuery,
            variables: {
                id: payload.id
            }
        });
    }

    async getNews(variables: NewsRequest) {
        return this.gqlClient.query<GetNewsGraphQLResponse>({
            query: GetNewsQuery,
            variables
        });
    }
}

export interface GetNewsGraphQLResponse {
    getNews: GetNewsResponse;
}

export interface GetNewsResponse {
    total: number;
    page: number;
    pageSize: number;
    documents: Content.Document[];
}

export default DocumentService;