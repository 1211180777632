import * as ContentStackCommon from '@mca-components-ui/contentstack-common';
import * as ContentStackProducts from '@mca-components-ui/contentstack-products';
import * as ContentStackSolutions from '@mca-components-ui/contentstack-solutions';

import Vue, { VueConstructor } from 'vue';

export const COMPONENTS = new Map<ContentStackCommon.Types.BlockId | ContentStackProducts.Types.BlockId | ContentStackSolutions.Types.BlockId, VueConstructor<Vue>>([
    ['cta', ContentStackCommon.Cta],
    ['introduction', ContentStackCommon.Intro],
    ['disclaimer', ContentStackCommon.Disclaimer],
    ['value_prop', ContentStackCommon.ValueProp],
    ['awards', ContentStackProducts.Awards],
    ['case_studies', ContentStackCommon.CaseStudies],
    ['capabilities_1', ContentStackProducts.Capabilities1],
    ['capabilities_2', ContentStackProducts.Capabilities2],
    ['features_1', ContentStackProducts.Features1],
    ['features_2', ContentStackProducts.Features2],
    ['process', ContentStackProducts.Process],
    ['capabilities', ContentStackSolutions.Capabilities],
    ['advice_timeline_process', ContentStackSolutions.AdviceTimelineProcess],
    ['whitepaper_single', ContentStackSolutions.WhitepaperSingle],
    ['whitepaper_multiple', ContentStackSolutions.WhitepaperMultiple]]);

export const ABOUT_US = {
    ContentType: 'about',
    Contact_Us_Entry_Id: 'blta46c81f585dc66d4',
    Advertise_With_Us_Entry_Id: 'blt422c5fb51498024f',
    Terms_Condition_Entry_Id: 'blt155994f83e35ab38'
};

export const LEARN_INVEST = {
    ContentType: 'learn_pages',
    Investing_Guides: 'blta9510b62236d1252',
    Webinars_Id: 'blt955006ba440fa14a',
    Podcasts_Id: 'blt8d05dfbe920bffb2',
    Articles_Id: 'bltb48386b47f356959',
    Getting_Started: 'blt0722373d52d0982f',
    Goals_Based_Portfolio_Construction: "blt9de50904be7ea893",
    Portfolio_Maintenance: "bltd187a8cda8abaf2c",
    Retirement_Strategies: "blt799f3d552611ae51",
    Morningstar_Equity_Research_Methodology: "bltd55f6b32d0f7114c",
    Morningstar_Manager_Research_Methodology: "blt3f83e75131ae8060",
    Stocks: "bltef9c1ad90d162a31",
    Bonds: "bltaf6a2e6421fd8e68",
    ETFs_Funds: "bltfb2b55e1aa49d68b",
    LICs: "blt1aa04ffd574ee626"
};

export const WEBINAR_PODCAST = {
    ContentType: 'webinars_and_podcasts',
    Webinar_Podcast_Id: "blt9784fdfddcbb001b"
};

export const THANK_YOU = new Map<string, { contentType: string; entryId: string }>([
    ["newsletter-financial-professional", {
        contentType: 'thankyou_pages_professional',
        entryId: 'blt10c81c57e670037e',
    }],
    ["newsletter-individual-investor", {
        contentType: 'thankyou_pages_individual',
        entryId: 'blt566c05739d3fec3f'
    }]
]);

export const BEST_IDEAS_SECTION = {
    ContentType: 'best_ideas_cards',
    EntryId: "blte375916d0ef2cfc6",
};

export const NEWSLETTER_SIGNUP_PAGE = {
    ContentType: 'newsletter',
    EntryId: "blt338eaffeb5f57318",
};

export const SECURITY_CTA = {
    ContentType: 'security_cta',
    EntryId: "bltad7603b9b5091af7",
};

export const NEWSLETTER_CTA = {
    ContentType: 'newsletter_cta'
};

export const FOOTER_PAGE = {
    ContentType: 'footer',
    EntryId: "blt7ae420b9a8d9e394",
};

export const HEAD_META = {
    ContentType: 'static_pages'
};

export const RECOMMENDED_SECTION_SAL = {
    ContentType: 'recommended_section_default'
};