import { MCA } from "@mca-components-ui/types";
import SearchQuery from "~/services/queries/getSearchResults.gql";
import getSearchDocuments from "~/services/queries/getSearchDocuments.gql";
import { getSearchSecurities, getSearchSecuritiesByType } from "~/services/queries/getSearchSecurities.gql";
import { ApolloClient } from "apollo-client";
import ISearchRequest from "~/types/document/ISearchRequest";
import { ISearchDocument, ISearchSecurity } from "~/types/document/ISearchResponse";

class SearchData {
    static searchQueryID = '';

    constructor(public gqlClient: ApolloClient<any>) { }

    async getSearchResultsFor(query: string) {

        try {
            if (SearchData.searchQueryID) {
                this.gqlClient.queryManager.stopQuery(SearchData.searchQueryID);
            }
            const queryClient = this.gqlClient.watchQuery({
                query: SearchQuery,
                variables: {
                    q: query,
                }
            });

            SearchData.searchQueryID = queryClient.queryId;

            const result = await queryClient.result();

            SearchData.searchQueryID = '';
            return result.data;
        }
        catch (err) {
            return Promise.reject("error message");
        }
    }
    async getSearchDocuments(searchRequest: ISearchRequest): Promise<ISearchDocument> {
        try {
            const result = await this.gqlClient.query({
                query: getSearchDocuments,
                variables: {
                    term: searchRequest.searchKeyword,
                    page: searchRequest.page,
                    pageSize: searchRequest.pageSize
                }
            });

            return result.data.searchDocuments as ISearchDocument;
        }
        catch (err) {
            return Promise.reject("error message");
        }
    }
    async getSecurities(searchRequest: ISearchRequest): Promise<{ [Key: string]: ISearchSecurity }> {
        try {
            const result = await this.gqlClient.query({
                query: getSearchSecurities,
                variables: {
                    term: searchRequest.searchKeyword,
                    page: searchRequest.page,
                    pageSize: searchRequest.pageSize,
                },
            });
            const returnObj = {
                stocks: {
                    total: result.data.stocks.total,
                    page: result.data.stocks.page,
                    pageSize: result.data.stocks.pageSize,
                    securities: result.data.stocks.securities,
                },
                funds: {
                    total: result.data.funds.total,
                    page: result.data.funds.page,
                    pageSize: result.data.funds.pageSize,
                    securities: result.data.funds.securities,
                },
                etfs: {
                    total: result.data.etfs.total,
                    page: result.data.etfs.page,
                    pageSize: result.data.etfs.pageSize,
                    securities: result.data.etfs.securities,
                },
                lics: {
                    total: result.data.lics.total,
                    page: result.data.lics.page,
                    pageSize: result.data.lics.pageSize,
                    securities: result.data.lics.securities,
                }
            };
            return returnObj;
        }
        catch (err) {
            return Promise.reject("error message");
        }
    }

    async getSecuritiesByType(searchRequest: ISearchRequest): Promise<ISearchSecurity> {
        try {
            const result = await this.gqlClient.query({
                query: getSearchSecuritiesByType,
                variables: {
                    term: searchRequest.searchKeyword,
                    securityType: searchRequest.securityType,
                    page: searchRequest.page,
                    pageSize: searchRequest.pageSize,
                },
            });
            const resObj = {
                total: result.data.searchSecurities.total,
                page: result.data.searchSecurities.page,
                pageSize: result.data.searchSecurities.pageSize,
                securities: result.data.searchSecurities.securities
            };
            return resObj;
        }
        catch (err) {
            return Promise.reject("error message");
        }
    }
    addHistoryItem(history: any, query: string): string[] {
        if (history.includes(query)) {
            history.splice(history.indexOf(query), 1);
        }
        history.unshift(query);
        history.splice(5);
        return history;
    }
    getAllSearchResults(
        searchResults: MCA.Api.SearchResult.ISecurity[]): MCA.Api.SearchResult.ISecurity[] {
        return searchResults;
    }
}

export default SearchData;
